<template>
  <b-row class="match-height align-items-center">
    <b-col>
      <b-form-group
        label="Ürün Kodu, Ürün Adı..."
        label-for="keyword"
      >
        <b-form-input
          id="keyword"
          v-model="filterParams.keyword"
          v-b-tooltip.hover
          title="Ürün kodun tamamınız yazmanız gerekmez. Örneğin 4 hanesini yazmanız yeterlidir."
          placeholder="Ürün Kodu, Ürün Adı..."
          @keydown.enter="getData"
        />
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        label="Ürün Grubu"
        label-for="id_product_groups"
      >
        <v-select
          id="id_product_groups"
          v-model="filterParams.id_product_groups"
          :options="productGroups"
          label="title"
          :reduce="item => item.id"
          placeholder="Seçiniz"
        />
      </b-form-group>
    </b-col>
    <b-col cols="auto">
      <b-button
        variant="primary"
        @click="getData"
      >
        <FeatherIcon icon="FilterIcon" />
        Filtrele
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'FilterCard',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterParams() {
      return this.$store.getters['products/filterParams']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
}
</script>
