<template>
  <div>
    <b-card>
      <filter-card :get-data="getDataList" />
    </b-card>
    <b-card no-body>
      <b-card-body class="text-right">
        <b-button
          variant="primary"
          :to="$route.path + '/add'"
        >
          <FeatherIcon icon="PlusCircleIcon" />
          Ürün Ekle
        </b-button>
      </b-card-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(pcode)="data">
          <div class="d-flex">
            <b-avatar
              v-if="data.item.image"
              :src=" baseURL + '/media/products/' + data.item.image"
            />
            <b-avatar
              v-else
              variant="primary"
            >
              <FeatherIcon icon="CameraIcon" />
            </b-avatar>
            <div class="ml-1">
              {{ data.item.title }}
              <div class="font-small-2 text-secondary">
                {{ data.item.pcode }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(width)="data">
          {{ data.item.width | toNumber }}
        </template>
        <template #cell(weight)="data">
          {{ data.item.weight | toNumber }}
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <list-buttons
              :edit-action="$route.path + '/edit/' + data.item.id"
              :delete-action="removeData"
              :data-id="data.item.id"
            />
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BAvatar,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FilterCard from '@/views/Admin/Products/Index/FilterCard.vue'

const tableName = 'products'
export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BAvatar,
    ListButtons,
    FilterCard,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
      pendingLoading: true,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'pcode',
          label: 'ÜRÜN KODU',
        },
        {
          key: 'product_group',
          label: 'ÜRÜN GRUBU',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.pcode AS pcode`,
          `${tableName}.title AS title`,
          'product_groups.title AS product_group',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['products/dataList']
    },
    dataCounts() {
      return this.$store.getters['products/dataCounts']
    },
    saveData() {
      return this.$store.getters['products/dataSaveStatus']
    },
    filterParams() {
      return this.$store.getters['products/filterParams']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getProductGroups()
  },
  methods: {
    getProductGroups() {
      this.$store.dispatch('productGroups/getDataList', {
        select: [
          'product_groups.id AS id',
          'product_groups.title AS title',
        ],
      })
    },
    getDataList() {
      const where = {}
      const orLike = {}
      if (this.filterParams.keyword) {
        orLike['products.pcode'] = this.filterParams.keyword
        orLike['products.title'] = this.filterParams.keyword
      }
      if (this.filterParams.id_product_groups) {
        where['products.id_product_groups'] = this.filterParams.id_product_groups
      }
      if (where) {
        this.dataQuery.where = where
      } else {
        this.dataQuery.where = {}
      }
      if (orLike) {
        this.dataQuery.or_like = orLike
      } else {
        this.dataQuery.or_like = {}
      }
      this.$store.dispatch('products/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('products/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
